<template>
    <div>
        <layout-header title=""></layout-header>
        <input type="text" id="deviceToken" v-show="LOCAL" placeholder="장치 토큰" readonly="readonly">
    </div>
</template>

<script>
import LayoutHeader from "@/pages/layouts/layoutHeader";
import $ from "jquery";

export default {
    name: "kakaoRestReturn",
    components: {LayoutHeader},
    data() {

        const KAKAO_RESPONSE_CODE = new URL(window.location.href).searchParams.get("code")

        const ENV = this.commonUtils.getEnv()

        const LOCAL = this.commonUtils.isLocal()

        return {

            BASE_URL: this.APIs.BASE_URL,

            KAKAO_LOGIN_KEY_REST: this.APIs.KAKAO_LOGIN_KEY_REST,
            KAKAO_LOGIN_RETURN_REST: this.APIs.KAKAO_LOGIN_RETURN_REST,
            KAKAO_RESPONSE_CODE,

            ENV,
            deviceToken: '',
            LOCAL

        }
    },
    mounted() {

        this.login()
        this.commonUtils.deviceToken('#deviceToken')

    },
    methods: {

        goReferrer() {
            if (!this.stringUtils.isEmpty(this.$route.query.referrer)) {
                location.href = this.$route.query.referrer
            } else {
                location.href = "/"
            }
        },

        login() {

            const self = this

            $.ajax({
                type: 'POST',

                url: 'https://kauth.kakao.com/oauth/token?'
                    + 'grant_type=' + 'authorization_code' + '&'
                    + 'client_id=' + self.KAKAO_LOGIN_KEY_REST + '&'
                    + 'redirect_uri=' + self.KAKAO_LOGIN_RETURN_REST + '&'
                    + 'code=' + self.KAKAO_RESPONSE_CODE,

                success: function (data) {

                    const contentType = 'application/x-www-form-urlencoded;charset=utf-8'
                    const accessToken = data.access_token

                    $.ajax({

                        url: self.APIs.OAUTH + '/kakao',

                        type: 'GET',

                        data: {
                            code: self.code,
                            accessToken: accessToken,
                            contentType: contentType,
                            redirectUri: self.KAKAO_LOGIN_RETURN_REST
                        },

                        success: function (data) {

                            let snsId
                            let email

                            try {
                                snsId = JSON.parse(data.result.body)['id']
                                email = JSON.parse(data.result.body)['kakao_account']['email']
                            } catch (e) {
                                self.commonUtils.localWarn(e)
                            }

                            self.commonUtils.localWarn("STATUS ::: " + data.status, "snsId : " + snsId, "email : " + email)

                            self.axiosCaller.get(self, self.APIs.SESSION + '/doSnsLogin', {
                                snsService: "kakao",
                                snsId: snsId,
                                email: email,
                                deviceToken: (self.webUtils.isDom('#deviceToken')) ? document.querySelector('#deviceToken').value : "",
                            }, async (res) => {

								console.log(res)

                                const result = res.data

                                if (result.status == 'duplicate') {

                                    self.swalUtils.gritter("이미 가입된 계정입니다.", result.service + "으로 로그인 해 주세요.", "warning", 3000)
                                        .then(t => {
                                            self.$router.push({
                                                name: "snsJoin"
                                            })
                                        })

                                } else if (!result.coAccountVO.isUse) {
									self.swalUtils.fire("탈퇴된 계정입니다.", "관리자에게 문의바랍니다.", "info", null, null).then(
										r => {
											self.$router.push({
												name: "snsJoin"
											})
										}
									)
								} else {

                                    if (await self.setMainSessionProc(result.coAccountVO)) {
                                        self.setMainSessionKeep(self.isKeepSession)

                                        await self.$router.push({
                                            name: "profile"
                                        })
                                    }

                                }


                            }, (e) => {
                                self.commonUtils.localErr(e)
                            })

                        }
                    }, (e) => {
                        this.commonUtils.localErr(e)
                    })
                },
                fail: function (e) {
                    this.commonUtils.localErr(e)
                }
            })
        }
    }

}
</script>

<style scoped>

</style>